.why {
  padding: 100px 0;

  p {
    color: #a3a3a3;
  }
}

.whyTitle {
  color: $black;
  padding: 15px 0;
  font-size: 22px;
  font-family: "Poppins-Bold";
}

.steps {
  color: $white;
  text-align: center;
  h4 {
    font-size: 20px;
    font-family: "Poppins-Bold";
  }
  img {
    margin: auto;
    height: 120px;
  }
  .curveArrow {
    width: 150px;
    float: right;
  }
}

.faq-item {
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px solid #828282;
  border-radius: 5px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.faq-item .question {
  color: $black;
  font-size: 18px;
  margin: 0;
  padding: 10px;
}

.faq-item .answer {
  padding: 10px;
  border-top: none;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  color: $black;
}

.faq-item .answer.active {
  overflow: visible;
}

@media only screen and (max-width: 1100px) {
  .faq-item {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .steps .curveArrow {
    display: none;
  }
}
