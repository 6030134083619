section.sliders {
  position: relative;

  /* .small-slider {
    position: absolute;
    right: 0;
    bottom: 0;
  } */

  .small-slider {
    width: 20%;
    padding: 20px 10px 20px 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  @media only screen and (max-width: 1400px) {
    .small-slider {
      width: 50%;
    }
  }
  @media only screen and (max-width: 820px) {
    .small-slider {
      width: 70%;
    }
  }
  @media only screen and (max-width: 550px) {
    .small-slider {
      width: 100%;
    }
  }
}

.place {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
}

.dubrovnik {
  background-image: url("../../assets/img/dubrovnik.jpg");
}

/* .plitvice {
  background-image: url("../../assets/img/plitvice.jpg");
} */

.krka {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/img/krka2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.place-wrapper {
  color: $white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  h1 {
    font-family: "Poppins-Bold";
    font-size: 64px;
  }
  p {
    width: 60%;
  }
}

.small {
  width: 170px !important;
  height: 115px;
  border-radius: 10px;
  padding: 5px;
}

@media only screen and (max-width: 960px) {
  .place-wrapper h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 820px) {
  .place-wrapper {
    h1 {
      font-size: 28px;
    }
    p {
      width: 100%;
    }
  }
  .nav {
    left: 5% !important;
    right: 5% !important;
  }
}
