@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Oraqle";
  src: url("../assets/fonts/Oraqle\ Script.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}
