.travel .parent-travel:nth-child(even) {
  background-color: rgba(205, 210, 255, 0.8);
}
.travel .parent-travel:nth-child(odd) {
  background-color: rgba(205, 255, 219, 0.8);
}

.travel {
  padding: 20px;
  color: $black;

  h4 {
    font-family: "Poppins-Bold";
    font-size: 22px;
  }
}

.travel-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  .wrap {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.bg-culture {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/img/culture-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.bg-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  height: 100vh;
  padding-bottom: 50px;
}

.card {
  background-repeat: no-repeat;
  background-size: cover;
  filter: none;
  transition: filter 0.5s ease;
  z-index: 1;

  &:hover {
    filter: blur(5px); /* add blur effect to the container */

    + .hover-button {
      visibility: visible !important;
      opacity: 1;
      transition: opacity 1s ease;
    }
  }

  .name-price {
    padding: 20px 30px;
  }

  h3 {
    font-size: 20px;
    font-family: "Poppins-Bold";
    color: $white;
  }
}

.hover-button {
  position: absolute;
  transition: opacity 1s ease;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.desc {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: $blue;
  padding: 10px 30px;
  color: $white;
}

.best {
  background-color: $blue;
}

.most {
  background-color: #ff3a3a;
}

@media only screen and (max-width: 800px) {
  .bg-section {
    p {
      width: 100%;
    }
  }
}
