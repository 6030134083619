.curve-title {
  font-family: "Oraqle";
  font-size: 64px;
  color: $blue;
}

.content-section {
  color: $black;
  gap: 30px;
}

.second-title {
  font-size: 64px;
  font-family: "Poppins-Bold";
}

@media only screen and (max-width: 1100px) {
  .second-title {
    font-size: 36px;
  }
}

.small-res {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .reverse {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .small-res {
    display: block;
  }
  .big-res {
    display: none;
  }
  .second-title {
    font-size: 28px;
  }
}
