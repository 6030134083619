.logo {
  a {
    font-family: "Poppins-Bold";
    font-size: 28px;
  }
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  right: 0;
  background-color: $white;
  top: 0;
  transition: 0.6s;
  z-index: 999;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 10px;
    padding: 0;

    li {
      text-align: center;
      font-size: 20px;
      padding-bottom: 10px;
      width: max-content;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.hamburger {
  z-index: 1000;
  display: none;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: $blue;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham5 .top {
  stroke-dasharray: 40 82;
}
.ham5 .bottom {
  stroke-dasharray: 40 82;
}
.ham5.active .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}
.ham5.active .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}

.dropdown {
  position: relative;
  display: inline-block;
  transition: all 1s ease-in-out;
  &:hover .dropdown-content {
    display: block;
    transition: all 1s ease-in-out;
  }
}

@media only screen and (max-width: 990px) {
  .links {
    display: none;
  }
  .hamburger {
    display: block;
    z-index: 1000;
  }
}
