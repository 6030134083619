form {
  border: 1px solid #cdcdcd;
  border-radius: 20px;
  padding: 10px;

  input {
    padding: 10px 20px;

    &:focus {
      outline: none;
    }
  }
}
