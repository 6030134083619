footer {
  padding: 100px 0;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-links {
  list-style-type: none;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .footer-wrapper {
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 660px) {
  .footer-links {
    flex-direction: column;
  }
}
